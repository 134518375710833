// import Vue from 'vue'
// import Router from 'vue-router'
// Vue.use(Router)
import store from '../store/index'
import Layout from '#/views/layout/Layout'
// in development-env not use lazy-loading, because lazy-loading too many pages will cause webpack hot update too slow. so only in production use lazy-loading;
// detail: https://panjiachen.github.io/vue-element-admin-site/#/lazy-loading

/* Layout */
const _import = require('./_import_' + process.env.NODE_ENV)
//需要这个两个文件配置 _import_production _import_production
/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
 **/
export const developmentRouterMap = [
  {
    path: '/',
    component: Layout,
    name: 'bbc-bus-pc-rf',
    children: [
      {
        path: '/supusergoodsList',
        component: _import('sunrich/goodsManager/channelgoodsMange/supusergoodsList'),
        name: '代理商商品列表'
      },
      {
        path: '/agentList',
        component: _import('agentManagement/agentList'),
        name: '代理商列表'
      },
      {
        path: '/historicalPushRecords',
        component: _import('productmanage/platPro/historicalPushRecords'),
        name: '代理商列表'
      },
      {
        path: '/historicalList',
        component: _import('productmanage/platPro/historicalList'),
        name: '代理商列表'
      },
      {
        path: '/priceDiscountList',
        component: _import('messageManage/priceDiscountList/index'),
        name: '满减活动列表'
      },
      {
        path: '/cgRefundList',
        component: _import('trading/Returns/cgReturns'),
        name: '采购退单列表'
      },
      {
        path: '/editPriceDiscountList',
        component: _import('marketingCenter/mallMarketingManage/priceDiscountList/components/editPriceDiscountList'),
        name: '新增编辑-满减活动列表'
      },
      {
        path: '/discountList',
        component: _import('messageManage/discountList/index'),
        name: '满折活动列表'
      },
      {
        path: '/addDiscountList',
        component: _import('marketingCenter/mallMarketingManage/discountList/components/addDiscountList'),
        name: '新增-满折活动列表'
      },
      {
        path: '/editDiscountList',
        component: _import('marketingCenter/mallMarketingManage/discountList/components/editDiscountList'),
        name: '编辑-满折活动列表'
      },
      {
        path: '/giveawayList',
        component: _import('messageManage/giveawayList/index'),
        name: '满赠活动列表'
      },
      {
        path: '/editGiveawayList',
        component: _import('marketingCenter/mallMarketingManage/giveawayList/components/editGiveawayList'),
        name: '新增编辑-满赠活动列表'
      },
      {
        path: '/seckillList',
        component: _import('messageManage/seckillList/index'),
        name: '秒杀活动列表'
      },
      {
        path: '/editSeckillList',
        component: _import('marketingCenter/mallMarketingManage/seckillList/components/editSeckillList'),
        name: '新增-秒杀活动列表'
      },
      {
        path: '/groupPurchaseList',
        component: _import('messageManage/groupPurchaseList/index'),
        name: '团购活动列表'
      },
      {
        path: '/editGroupPurchaseList',
        component: _import('marketingCenter/mallMarketingManage/groupPurchaseList/components/editGroupPurchaseList'),
        name: '新增-团购活动列表'
      },
      {
        path: '/voucherList',
        component: _import('messageManage/voucherList/index'),
        name: '代金券列表'
      },
      {
        path: '/addVoucherList',
        component: _import('marketingCenter/mallMarketingManage/voucherList/components/addVoucherList'),
        name: '新增-代金券列表'
      },
      {
        path: '/editVoucherList',
        component: _import('marketingCenter/mallMarketingManage/voucherList/components/editVoucherList'),
        name: '编辑-代金券列表'
      },
      {
        path: '/marketingAudit',
        component: _import('marketingCenter/marketingAudit/index'),
        name: '营销审核'
      },
      {
        path: '/liveListForPlat',
        component: _import('marketingCenter/liveListForPlat/liveListForPlat'),
        name: '直播管理'
      },
      {
        path: '/agentOpen',
        component: _import('agentManagement/agentOpen'),
        name: '代理商开通'
      },
      {
        path: '/dealerList',
        component: _import('dealerManagement/dealerList'),
        name: '经销商列表'
      },
      {
        path: '/dealerApply',
        component: _import('dealerManagement/dealerApply'),
        name: '经销商申请列表'
      },
      // //会员管理
      {
        path: '/memberList',
        component: _import('sunrich/memberManage/memberList'),
        name: '会员列表'
      },
      // // 店铺管理
      {
        path: '/shoplist',
        component: _import('sunrich/shopManage/shoplist'),
        name: '店铺列表'
      },
      {
        path: '/homePage',
        component: _import('index/homePage/index'),
        name: '首页大屏'
      },
      {
        path: '/openshopList',
        component: _import('sunrich/shopManage/openshopList'),
        name: '开店申请列表'
      },
      // // 平台协议
      // {
      //    path: '/purchasePrivacyInfoList',
      //    component: _import('sunrich/platformAgreement/purchaseMallAgreement/purchasePrivacyInfoList'),
      //    name: '采购用户隐私协议',
      // },
      // {
      //    path: '/purchaseDealerInfoPg',
      //    component: _import('sunrich/platformAgreement/purchaseMallAgreement/purchaseDealerInfoPg'),
      //    name: '采购设置入驻协议',
      // },
      // {
      //    path: '/purchasePrivacyInfo',
      //    component: _import('sunrich/platformAgreement/purchaseMallAgreement/purchasePrivacyInfo'),
      //    name: '采购设置隐私协议',
      // },
      // {
      //    path: '/purchaseDealerInfoList',
      //    component: _import('sunrich/platformAgreement/purchaseMallAgreement/purchaseDealerInfoList'),
      //    name: '采购用户入驻协议',
      // },
      // {
      //    path: '/retailPrivacyInfoList',
      //    component: _import('sunrich/platformAgreement/retailMallAgreement/retailPrivacyInfoList'),
      //    name: '零售用户隐私协议',
      // },
      // {
      //    path: '/retailDealerInfoPg',
      //    component: _import('sunrich/platformAgreement/retailMallAgreement/retailDealerInfoPg'),
      //    name: '零售设置入驻协议',
      // },
      // {
      //    path: '/retailPrivacyInfo',
      //    component: _import('sunrich/platformAgreement/retailMallAgreement/retailPrivacyInfo'),
      //    name: '零售设置隐私协议',
      // },
      // {
      //    path: '/retailDealerInfoList',
      //    component: _import('sunrich/platformAgreement/retailMallAgreement/retailDealerInfoList'),
      //    name: '零售用户入驻协议',
      // },
      {
        path: '/partnerList',
        component: _import('sunrich/partnerManage/partnerList'),
        name: '合伙人列表'
      },
      {
        path: '/partnerAccountList',
        component: _import('sunrich/partnerCommission/partnerAccountList'),
        name: '合伙人账户列表'
      },
      {
        path: '/distribStatementPM',
        component: _import('sunrich/partnerCommission/distribStatementPM'),
        name: '佣金流水列表'
      },
      {
        path: '/newAddDistribRule',
        component: _import('sunrich/partnerCommission/newAddDistribRule'),
        name: '基础分佣设置'
      },
      {
        path: '/newAddDistribGoods',
        component: _import('sunrich/partnerCommission/newAddDistribGoods'),
        name: '商品分佣设置'
      },
      {
        path: '/newSetDistribGoods',
        component: _import('sunrich/partnerCommission/newSetDistribGoods'),
        name: '商品分佣列表'
      },
      {
        path: '/withdrawalApplication',
        component: _import('sunrich/withdrawalApplication/index'),
        name: '分佣改造'
      },
      // //商品中心
      {
        path: '/productPush',
        component: _import('productmanage/platPro/productPush'),
        name: '发布商品'
      },
      {
        path: '/platProListZq',
        component: _import('productmanage/release/indexZQ'),
        name: '发布商品'
      },
      {
        path: '/platSkuList',
        component: _import('productmanage/platPro/platSkuListZq'),
        name: '商品列表——查看'
      },
      {
        path: '/platProEdit',
        component: _import('productmanage/platPro/platProEditZq'),
        name: '商品列表——编辑'
      },
      {
        path: '/smRelease',
        component: _import('goodsForUser/smGoods/indexPg'),
        name: '发布组合商品'
      },
      {
        path: '/skuPgList',
        component: _import('goodsForUser/smGoods/editPg'),
        name: '编辑组合商品'
      },
      {
        path: '/smEditPg',
        component: _import('goodsForUser/smGoods/skuPgList'),
        name: '组合商品列表'
      },
      {
        path: '/skupglistShow',
        component: _import('goodsForUser/smGoods/skupglistShow'),
        name: '组合商品列表查看'
      },
      {
        path: '/distributorgoodsList',
        component: _import('sunrich/goodsManager/channelgoodsMange/distributorgoodsList'),
        name: '经销商商品列表'
      },

      {
         path: '/agentgoodsList',
         component: _import('sunrich/goodsManager/channelgoodsMange/agentgoodsList'),
         name: '代理商商品列表',
      },
      {
         path: '/agentgoodsListShow',
         component: _import('sunrich/goodsManager/channelgoodsMange/agentgoodsListShow'),
         name: '代理商商品列表-查看',
      },
      {
         path: '/distributorgoodsList',
         component: _import('sunrich/goodsManager/channelgoodsMange/distributorgoodsList'),
         name: '分销云仓商品列表',
      },
      {
         path: '/distributorgoodsListShow',
         component: _import('sunrich/goodsManager/channelgoodsMange/distributorgoodsListShow'),
         name: '分销云仓商品列表-查看',
      },
      {
        path: '/distributorbuygoodsList',
         component: _import('sunrich/goodsManager/channelgoodsMange/distributorbuygoodsList'),
         name: '采购云仓商品列表',
      },
      {
         path: '/distributorbuygoodsListShow',
         component: _import('sunrich/goodsManager/channelgoodsMange/distributorbuygoodsListShow'),
         name: '采购云仓商品列表-查看',
      },
      {
         path: '/smartmallgoodsList',
         component: _import('sunrich/goodsManager/channelgoodsMange/smartmallgoodsList'),
         name: '智慧商城商品列表',
      },
      {
         path: '/smartmallgoodsListShow',
         component: _import('sunrich/goodsManager/channelgoodsMange/smartmallgoodsListShow'),
         name: '智慧商城商品列表-查看',
      },
      {
         path: '/distributionmallgoodsList',
         component: _import('sunrich/goodsManager/channelgoodsMange/distributionmallgoodsList'),
         name: '分销商城商品列表',
      },
      {
         path: '/distributionmallgoodsListShow',
         component: _import('sunrich/goodsManager/channelgoodsMange/distributionmallgoodsListShow'),
         name: '分销商城商品列表-查看',
      },
      {
        path: '/purchaseGoodsList',
        component: _import('sunrich/goodsManager/channelgoodsMange/purchaseGoodsList'),
        name: '采购商城商品列表'
      },
      {
        path: '/purchaseGoodsListShow',
        component: _import('sunrich/goodsManager/channelgoodsMange/purchaseGoodsListShow'),
        name: '采购商城商品列表-查看'
      },
      {
        path: '/retailOrderList',
        component: _import('orderManage/retailOrderList/listAll'),
        name: '零售订单列表'
      },
      {
        path: '/integralOrderList',
        component: _import('orderManage/integralOrderList/listAll'),
        name: '积分订单列表'
      },
      {
        path: '/balanceDetails',
        component: _import('financialCenter/accountManage/balanceDetails/index'),
        name: '在途余额明细'
      },
      {
        path: '/accountWithdrawal',
        component: _import('financialCenter/accountManage/accountWithdrawal/index'),
        name: '账号提现'
      },
      {
        path: '/putgoodsList',
        component: _import('sunrich/goodsManager/agentgoodsAudit/putgoodsList'),
        name: '已上架商品列表'
      },
      // // 代理商商品审核
      {
         path: '/bereviewedgoodsList',
         component: _import('sunrich/goodsManager/agentgoodsAudit/bereviewedgoodsList'),
         name: '待审核商品列表',
      },
      {
         path: '/listCommon',
         component: _import('sunrich/goodsManager/agentgoodsAudit/listCommon'),
         name: '待审核商品列表-查看',
      },
      // {
      //    path: '/putgoodsList',
      //    component: _import('sunrich/goodsManager/agentgoodsAudit/putgoodsList'),
      //    name: '已上架商品列表',
      // },
      // {
      //    path: '/beputgoodsList',
      //    component: _import('sunrich/goodsManager/agentgoodsAudit/beputgoodsList'),
      //    name: '待上架商品列表',
      // },
      // {
      //    path: '/stopgoodsList',
      //    component: _import('sunrich/goodsManager/agentgoodsAudit/stopgoodsList'),
      //    name: '停用商品列表',
      // },
      // //商品配置
      // {
      //    path: '/smartmallClass',
      //    component: _import('sunrich/goodsManager/goodsConfig/smartmallClass'),
      //    name: '智慧商城分类',
      // },
      // {
      //    path: '/shopmallClass',
      //    component: _import('sunrich/goodsManager/goodsConfig/shopmallClass'),
      //    name: '采购商城分类',
      // },
      // {
      //    path: '/distmallClass',
      //    component: _import('sunrich/goodsManager/goodsConfig/distmallClass'),
      //    name: '分销商城分类',
      // },
      // // {
      // //    path: '/goodslibraryClass',
      // //    component: _import('sunrich/goodsManager/goodsConfig/goodslibraryClass'),
      // //    name: '商品库分类',
      // // },
      // {
      //    path: '/dalei',
      //    component: _import('productmanage/productclassify/components/dalei'),
      //    name: '商品库分类',
      // },
      // {
      //    path: '/goodsType',
      //    component: _import('sunrich/goodsManager/goodsConfig/goodsType'),
      //    name: '商品类型',
      // },
      // {
      //    path: '/goodsSpecifications',
      //    component: _import('sunrich/goodsManager/goodsConfig/goodsSpecifications'),
      //    name: '商品规格',
      // },
      // {
      //    path: '/goodsParms',
      //    component: _import('sunrich/goodsManager/goodsConfig/goodsParms'),
      //    name: '商品参数',
      // },
      // {
      //    path: '/goodsAttribute',
      //    component: _import('sunrich/goodsManager/goodsConfig/goodsAttribute'),
      //    name: '商品属性',
      // },
      // {
      //    path: '/goodsBrand',
      //    component: _import('sunrich/goodsManager/goodsConfig/goodsBrand'),
      //    name: '商品品牌',
      // },
      // {
      //    path: '/goodsManager',
      //    component: _import('sunrich/goodsManager/goodsConfig/goodsManager'),
      //    name: '图片管理',
      // },
      // //渠道中心
      // {
      //    path: '/disGoodSet',
      //    component: _import('disChannel/set/disGoodSet'),
      //    name: '渠道商品设置',
      // },
      // {
      //    path: '/disPriceSet',
      //    component: _import('disChannel/set/disPriceSet'),
      //    name: '渠道价格设置',
      // },
      {
        path: '/disPriceRange',
        component: _import('disChannel/set/disPriceRange'),
        name: '渠道价格范围'
      },
      // {
      //    path: '/disGoodsRange',
      //    component: _import('disChannel/set/disGoodsRange'),
      //    name: '渠道商品范围',
      // },
      // //内容中心，内容配置
      {
        path: '/cmsTginfo',
        component: _import('sunrich/content/indexbus'),
        name: '内容配置'
      },
      // //交易中心
      // {
      //    path: '/payPrescription',
      //    component: _import('tradePg/payPrescription'),
      //    name: '订单设置',
      // },
      // // 财务中心
      {
        path: '/makeInvoiceListPlatYs',
        component: _import('checkInvoice/makeInvoiceListPlatYs'),
        name: '待开票列表'
      },
      {
        path: '/stayInvoicePlat',
        component: _import('checkInvoice/stayInvoicePlat'),
        name: '待申请列表'
      },
      {
        path: '/purchaseOrderList',
        component: _import('orderManage/purchaseOrderList/listAll'),
        name: '采购订单列表'
      },
      {
        path: '/returnsp',
        component: _import('trading/Returns/spReturns'),
        name: '零售退单列表'
      },
      // // demo
      // {
      //    path: '/lodash',
      //    component: _import('demo/lodash/index'),
      //    name: 'lodash_demo',
      // },
      {
        path: '/platProList',
        component: _import('productmanage/platPro/platProListZq'),
        name: '商品列表'
      },
      {
        path: '/productsForm',
        component: _import('products/form/ProductsForm'),
        name: '商品表单（新增/编辑）'
      },
      {
        path: '/combinedProductsForm',
        component: _import('products/form/CombinedProductsForm'),
        name: '组合商品表单（新增/编辑）'
      },
      {
        path: '/rebateReconciliationList',
        component: _import(
          'financialCenter/rebateManage/rebateReconciliationList/index'
        ),
        name: '返利对账列表',
      },
      {
        path: '/agentReconciliationList',
        component: _import(
          'reportCenter/reconciliationManage/agentReconciliationList/index'
        ),
        name: '代理商对账列表',
      },
      {
        path: '/storeOrderStatistics',
        component: _import('dataCenter/storeOrderStatistics/index'),
        name: '店铺订单统计',
      },
      {
        path: '/storeOrderStatistics_details',
        component: _import('dataCenter/storeOrderStatistics/components/details'),
        name: '店铺订单统计-查看',
      },
      {
        path: '/partnerRecommendationStatistics',
        component: _import('dataCenter/partnerRecommendationStatistics/index'),
        name: '合伙人推荐统计',
      },
      {
        path: '/partnerRecommendationStatistics_laxin',
        component: _import(
          'dataCenter/partnerRecommendationStatistics/components/laxin'
        ),
        name: '合伙人推荐统计-拉新',
      },
      {
        path: '/partnerRecommendationStatistics_daidan',
        component: _import(
          'dataCenter/partnerRecommendationStatistics/components/daidan'
        ),
        name: '合伙人推荐统计-带单',
      },
      {
        path: '/storeAfterSalesStatistics',
        component: _import('dataCenter/storeAfterSalesStatistics/index'),
        name: '店铺售后统计',
      },
      {
        path: '/storeAfterSalesStatistics_details',
        component: _import(
          'dataCenter/storeAfterSalesStatistics/components/details'
        ),
        name: '店铺售后统计-查看',
      },
      {
        path: '/promotionStatistics',
        component: _import('dataCenter/promotionStatistics/index'),
        name: '促销统计',
      },
      {
        path: '/promotionStatistics_details',
        component: _import('dataCenter/promotionStatistics/components/details'),
        name: '促销统计-查看',
      },
      {
                path: '/administratorlist',
                name: '管理员列表',
                component: _import('operatorList/index'),
                meta: {
                    title: '管理员列表',
                    icon: 'form'
                }
            },
    ]
  }
]

export const productionRouterMap = [
  // 登录页
  {
    path: '/login',
    component: _import('index/login/index'),
    name: '登录'
  },
  {
    path: '/resetPassword',
    component: _import('index/resetPassword/index'),
    name: '忘记密码'
  }
]

export default new VueRouter({
  // mode: 'history', //后端支持可开
  scrollBehavior: () => ({
    y: 0
  }),
  routes: process.env.NODE_ENV == 'development' ? developmentRouterMap : productionRouterMap
})
